<template>
  <header v-if="this.$route.path !== `/${this.$i18n.locale}/terms`">
    <v-app-bar
      app
      fixed
      :elevation="2"
      height="50"
      style="background-color: #fff; z-index: 200"
    >
      <v-row no-gutters>
        <v-col cols="1" class="d-flex">
          <v-icon
            v-if="
              this.$store.state.castDialog.selectedCastProfileDialog ||
              this.$store.state.castDialog.selectedCastReserveDialog
            "
            size="1.5rem"
            color="#333"
            v-on:click="closeCastDialog()"
            >mdi-window-close</v-icon
          >
          <v-icon
            v-else-if="this.$store.state.siteMemberDialog.openDialog"
            size="1.5rem"
            color="#333"
            v-on:click="closeSiteMemberProfileDialog()"
            >mdi-window-close</v-icon
          >
          <!-- <v-icon
            v-else-if="
              this.$store.state.siteMemberHelpfullCastReviewDialog.openDialog
            "
            size="1.5rem"
            color="#333"
            v-on:click="closeSiteMemberHelpfullCastReviewDialog()"
            >mdi-window-close</v-icon
          > -->
          <v-icon
            v-else-if="$route.query.evaluate == '1'"
            size="1.5rem"
            color="#333"
            v-on:click="$router.push(`/${$i18n.locale}/mypage/`)"
            >mdi-chevron-left</v-icon
          >
          <v-icon
            v-else-if="$route.query.usage == '0 ' || $route.query.usage == '1'"
            size="1.5rem"
            color="#333"
            v-on:click="
              $router.push({
                path: `/${$i18n.locale}/mypage/`,
                query: { usage: $route.query.usage },
              })
            "
            >mdi-chevron-left</v-icon
          >
          <v-icon
            v-else-if="
              this.$route.path !== `/${$i18n.locale}` &&
              this.$route.path !== `/${$i18n.locale}/call`
            "
            size="1.5rem"
            color="#333"
            v-on:click="$router.go(-1)"
            >mdi-chevron-left</v-icon
          >
        </v-col>

        <!-- logo -->
        <v-col cols="9" v-on:click="toLink(`/${$i18n.locale}`)">
          <div class="text-center" style="height: 50px">
            <nuxt-link to="" class="d-inline-block" style="height: 100%">
              <img height="100%" class="py-1" contain :src="logo" />
            </nuxt-link>
          </div>
        </v-col>

        <!-- logo -->
        <!-- <v-col>
          <div class="text-center" style="font-size: 24px">
            <nuxt-link
              :to="`/${$i18n.defaultLocale}`"
              style="text-decoration: none"
              >Tokyo Escort OTOME</nuxt-link
            >
          </div>
        </v-col> -->

        <v-col
          v-if="!this.$store.state.notification.castEvaluation"
          cols="2"
          class="d-flex justify-end aling-center"
          style="align-items: center"
        >
          <!-- <nuxt-link to="/notice" tag="div" style="cursor:pointer;" class="d-inline-block mr-2">
            <v-icon v-if="this.$store.state.notification.noticeNotificationUncheckCount>0" size="1.5rem" color="#B22C27" style="position:relative;">mdi-bell-alert</v-icon>
          </nuxt-link> -->
          <v-btn
            :v-if="this.$route.path != `/${$i18n.defaultLocale}/login`"
            @click.stop="navigation = !navigation"
            icon
            height="20"
            width="20"
          >
            <v-badge
              color="#B22C27"
              overlap
              v-model="this.$store.state.notification.notificationBadgesFlag"
            >
              <template v-slot:badge><span>!</span></template>
              <v-icon color="primary">mdi-menu</v-icon>
            </v-badge>
          </v-btn>
        </v-col>
      </v-row>
    </v-app-bar>

    <!-- ナビゲーションバー -->
    <v-navigation-drawer 
      v-model="navigation"
      disable-resize-watcher
      app
      right
      style="z-index: 190"
    >
      <div
        v-on:click="navigation = !navigation"
        class="pa-2 text-right"
        style="font-size: var(--read-font-size-s); font-weight: bold"
      >
        <v-icon class="mr-2">mdi-window-close</v-icon>
      </div>
      <v-divider></v-divider>
      <v-list dense class="pa-0" id="navigation-list">
        <!-- お知らせ一覧 -->
        <!-- <v-list-item class="ma-0 pa-0 pl-6" v-on:click="toLink('/notice')">
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <v-badge color="#B22C27" overlap v-model="noticeNotificationFlag">
                <template v-slot:badge><span>!</span></template>
                <IconNews
                  class="mr-4"
                  style="fill:#5d5d5d;stroke:#5d5d5d;width:21px;height:21px;"
                />
              </v-badge>
              <div class="menu-title">お知らせ一覧</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke:#ff7796;" />
            </v-col>
          </v-row>
        </v-list-item> -->
        <!-- 口コミ -->
        <!-- <v-list-item class="ma-0 pa-0 pl-6" v-on:click="toLink('/cast-review')">
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconReview
                class="mr-4"
                style="fill:#5d5d5d;width:21px;height:21px;"
              />
              <div class="menu-title">クチコミ</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke:#ff7796;" />
            </v-col>
          </v-row>
        </v-list-item> -->
        <!-- 予約一覧 -->
        <v-list-item
          class="ma-0 pa-0 pl-6"
          id="toReserve"
          v-on:click="toLink(`/${$i18n.locale}/reserve`)"
          :disabled="isGuest"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <v-badge
                color="#B22C27"
                overlap
                v-model="this.$store.state.notification.notificationBadgesFlag"
              >
                <template v-slot:badge><span>!</span></template>
                <IconReserve2
                  class="mr-4"
                  style="
                    fill: #5d5d5d;
                    stroke: #5d5d5d;
                    width: 21px;
                    height: 21px;
                  "
                />
              </v-badge>
              <div class="menu-title">{{ $t('reservation_list') }}</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke: #ff7796" />
            </v-col>
          </v-row>
        </v-list-item>
        <!-- キャンセル通知一覧 -->
        <!-- <v-list-item
          class="ma-0 pa-0 pl-6"
          v-on:click="toLink('/reserve/reservation-waitlist')"
          :disabled="isGuest"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <div class="mr-4" style="width: 21px;height: 21px;">
                <IconCancel
                  style="fill:#5d5d5d;stroke:#5d5d5d;width:100%;height:100%;"
                />
              </div>
              <div class="menu-title">キャンセル通知一覧</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke:#ff7796;" />
            </v-col>
          </v-row>
        </v-list-item> -->
        <!-- 掲載店舗一覧 -->
        <!-- <v-list-item class="ma-0 pa-0 pl-6" v-on:click="toLink('/shop-list')">
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconShop3
                class="mr-4"
                style="fill:#5d5d5d;stroke:#5d5d5d;width:21px;height:21px;"
              />
              <div class="menu-title">掲載店舗一覧</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke:#ff7796;" />
            </v-col>
          </v-row>
        </v-list-item> -->
        <!-- アカウント -->
        <v-list-item
          class="ma-0 pa-0 pl-6"
          v-on:click="toLink(`/${$i18n.locale}/account`)"
          :disabled="isGuest"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconMypage
                class="mr-4"
                style="fill: #5d5d5d; width: 21px; height: 21px"
              />
              <div class="menu-title">{{ $t('account') }}</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke: #ff7796" />
            </v-col>
          </v-row>
        </v-list-item>
        <!-- ヘルプ・その他 -->
        <v-list-item
          class="ma-0 pa-0 pl-6"
          v-on:click="toLink(`/${$i18n.locale}/help`)"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconHelp
                class="mr-4"
                style="fill: #5d5d5d; width: 21px; height: 21px"
              />
              <div class="menu-title">{{ $t('help_other') }}</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke: #ff7796" />
            </v-col>
          </v-row>
        </v-list-item>
        <!-- ログアウト -->
        <v-list-item
          v-show="!isGuest"
          class="ma-0 pa-0 pl-6"
          v-on:click="logoutDialog = !logoutDialog"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconLogout
                class="mr-4"
                style="fill: #5d5d5d; width: 21px; height: 21px"
              />
              <div class="menu-title">{{ $t('logout') }}</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke: #ff7796" />
            </v-col>
          </v-row>
        </v-list-item>
        <!-- Line連携 -->
        <!-- <v-list-item
          class="ma-0 pa-0 pl-6"
          v-on:click="toLink('/line')"
          :disabled="isGuest"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconLine class="mr-4" style="width:21px;height:21px;" />
              <div class="menu-title">LINE連携</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke:#ff7796;" />
            </v-col>
          </v-row>
        </v-list-item> -->
        <!-- ログイン/会員登録 -->
        <v-list-item
          class="ma-0 pa-0 pl-6"
          v-show="isGuest"
          v-on:click="toLogin()"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <v-icon class="mr-4">mdi-account-plus</v-icon>
              <div class="menu-title">{{ $t('login_register') }}</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke: #ff7796" />
            </v-col>
          </v-row>
        </v-list-item>
        <!-- リクルートボタン -->
        <v-list-item
          class="ma-0 pa-0 pl-6"
          v-on:click="toLink(`recruit`)"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconRecruit
                class="mr-4"
                style="fill: #5d5d5d; width: 21px; height: 21px"
              />
              <div class="menu-title">{{ $t('recruit') }}</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke: #ff7796" />
            </v-col>
          </v-row>
        </v-list-item>
        <!-- アフィリエイトボタン -->
        <v-list-item
          class="ma-0 pa-0 pl-6"
          v-on:click="toLink(`/${$i18n.locale}/affiliate`)"
        >
          <v-row class="ma-0 pa-0">
            <v-col cols="10" class="ma-0 pa-0 d-flex align-center">
              <IconAffiliate
                class="mr-4"
                style="fill: #5d5d5d; width: 21px; height: 21px"
              />
              <div class="menu-title over_word">{{ $t('affiliate') }}</div>
            </v-col>
            <v-col cols="2" class="ma-0 pa-0 d-flex align-center pl-3">
              <Arrow style="stroke: #ff7796" />
            </v-col>
          </v-row>
        </v-list-item>
      </v-list>
      <div class="home-display-add mt-3">
        <div class="home-display-add__title">
          {{ $t('text_4k5z') }}
        </div>
        <div class="home-display-add__body">
          <div class="home-display-add__description mb-3">
            <span class="d-block text-center">{{ $t('text_9jjh') }}</span>
          </div>
          <div class="home-display-add__button d-flex pb-3">
            <div
              v-on:click="
                homeDisplayAddDialog = !homeDisplayAddDialog;
                osType = 'ios';
              "
            >
              iPhone
            </div>
            <div
              v-on:click="
                homeDisplayAddDialog = !homeDisplayAddDialog;
                osType = 'android';
              "
            >
              Android
            </div>
          </div>
        </div>
      </div>
    </v-navigation-drawer>

    <!-- ログアウト確認ダイアログ -->
    <v-dialog
      v-model="logoutDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="pa-4 text-center"
        color="primary"
        style="
          color: #fff;
          font-size: var(--read-font-size-m);
          font-weight: bold;
        "
      >
        <div>{{ $t('logout_confirm') }}</div>
        <v-btn color="#fff" class="mt-4 mr-1"
          ><a
            :href="logoutUrl"
            style="text-decoration: none; font-weight: bold"
            >{{ $t('logout') }}</a
          ></v-btn
        >
        <v-btn
          color="#fff"
          class="mt-4 ml-1"
          v-on:click="logoutDialog = !logoutDialog"
          style="font-weight: bold"
          >{{ $t('cancel') }}</v-btn
        >
      </v-card>
    </v-dialog>

    <!-- ホーム画面追加方法ダイアログ -->
    <v-dialog
      v-model="homeDisplayAddDialog"
      max-width="400"
      transition="dialog-bottom-transition"
      style="z-index: 9999"
    >
      <v-card
        class="pa-4"
        color="primary"
        style="
          color: #fff;
          font-size: var(--read-font-size-m);
          font-weight: bold;
        "
      >
        <ol>
          <li
            v-for="(description, index) in additionalOrderDescription()"
            :key="index"
          >
            {{ description }}
          </li>
        </ol>
        <div class="text-center">
          <v-btn
            color="#fff"
            class="mt-4 ml-1 text-center"
            v-on:click="homeDisplayAddDialog = !homeDisplayAddDialog"
            style="font-weight: bold"
          >
            {{ $t('close') }}
          </v-btn>
        </div>
      </v-card>
    </v-dialog>
  </header>
</template>

<script>
import logo from '~/assets/img/logo.png';
import lineIcon from '~/assets/img/line_icon.png';
import IconNews from '~/assets/img/icon-news.svg';
import Arrow from '~/assets/img/arrow.svg';
import IconReview from '~/assets/img/icon-review.svg';
import IconReserve2 from '~/assets/img/icon-reserve2.svg';
import IconCancel from '~/assets/img/icon-cancel.svg';
import IconShop3 from '~/assets/img/icon-shop3.svg';
import IconMypage from '~/assets/img/icon-mypage.svg';
import IconHelp from '~/assets/img/icon-help.svg';
import IconLogout from '~/assets/img/icon-logout.svg';
import IconLine from '~/assets/img/icon-line.svg';
import IconRecruit from '~/assets/img/icon-recruit.svg';
import IconAffiliate from '~/assets/img/icon-affiliate.svg';

export default {
  components: {
    IconNews,
    Arrow,
    IconReview,
    IconReserve2,
    IconCancel,
    IconShop3,
    IconMypage,
    IconHelp,
    IconLogout,
    IconLine,
    IconRecruit,
    IconAffiliate,
  },
  data() {
    return {
      isGuest: !this.$store.state.auth.loggedIn,
      logo: logo,
      navigation: false,
      lineIcon: lineIcon,
      logoutDialog: false,
      homeDisplayAddDialog: false,
      osType: '',
      language: '',
      logoutUrl: `https://${process.env.AUTH0_DOMAIN}/v2/logout?returnTo=${process.env.APP_URL}/${this.$i18n.defaultLocale}/logout&client_id=${process.env.AUTH0_CLIENT_ID}`,
    };
  },
  created() {
    if (this.$i18n.locale == 'zh-cn') {
      this.language = 'zh-CN';
    } else if (this.$i18n.locale == 'zh-tw') {
      this.language = 'zh-TW';
    } else if (this.$i18n.locale == 'ko') {
      this.language = 'ko';
    } else {
      this.language = this.$i18n.locale;
    }
    const param = {
      language: this.language
    };
    this.$axios
      .post('public/get-customer-select-Language', param, {})
      .then((response) => {
      })
      .finally(() => {
      });
  },
  watch: {
    navigation() {
      // キャンセル一覧を選択時は予約と同じディレクトリなので、予約のClassを未選択状態に書き換える
      if (
        this.navigation &&
        this.$route.name == 'reserve-reservation-waitlist'
      ) {
        document.getElementById('toReserve').className =
          'v-list-item v-list-item--link theme--light';
      }
    },
  },
  methods: {
    toLink(url) {
      if (url === 'recruit') {
        url = 'https://kanto.qzin.jp/tokyoescort/?v=official';
        window.open(url, '_blank');
      } else {
        this.$router.push(url);
      }
    },
    additionalOrderDescription() {
      return this.osType === 'ios'
        ? [
            this.$t('text_8j7h'),
            this.$t('text_pj4f'),
            this.$t('text_k2gp'),
            this.$t('text_m8gh'),
          ]
        : [
            this.$t('text_2s77'),
            this.$t('text_e99k'),
            this.$t('text_4c27'),
            this.$t('text_dti8'),
          ];
    },
  },
  computed: {
    noticeNotificationFlag() {
      return this.$store.state.notification.noticeNotificationUncheckCount > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.v-list-item--disabled {
  opacity: 0.2;
}
.menu-title {
  font-size: 0.875rem;
  font-weight: bold;
  color: var(--read-font-color-primary);
}
::v-deep .v-list-item {
  height: 60px !important;
  .v-badge__badge {
    right: 2px !important;
  }
}
#navigation-list {
  margin-top: 10px;
}
.over_word{
  white-space: nowrap;
  overflow: visible;
}

.home-display-add {
  margin: auto;
  width: 90%;
  .home-display-add__title {
    background: #4267b2;
    border-radius: 10px 10px 0 0;
    color: #fff;
    font-size: 1rem;
    font-weight: bold;
    text-align: center;
    height: 34px;
    line-height: 34px;
  }
  .home-display-add__body {
    background: #f0f3f4;
    border-radius: 0 0 10px 10px;
    padding: 10px 0 10px 0;
    .home-display-add__description {
      color: #5d5d5d;
      font-weight: bold;
      font-size: 0.75rem;
    }
    .home-display-add__button {
      > div {
        background: #4267b2;
        border-radius: 5px;
        color: #fff;
        font-size: 0.75rem;
        font-weight: bold;
        margin-left: 6.5%;
        padding: 3px 0 3px 0;
        text-align: center;
        width: 40%;
        &:hover {
          cursor: pointer;
        }
      }
    }
  }
}
</style>
